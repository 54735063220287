@import "_placeholder.scss";
@import "_border-radius.scss";
@import "_Colors.scss";


html {font-size:10px;}

body {
 &.modal-open{padding-right:0 !important;}  
}

::-moz-selection {
    background-color:$blue;
    color: #FFF;
}

::selection {
    background-color: $blue;
    color: #FFF;
}

.container {
    @media (min-width:1400px){ width:1400px;}

}

.full-intro .header {position:fixed;height:100px; top:0;}
.body-section {margin-top:100px;}


.lightGreybg {background-color:$lightGrey;}
.bluebg {background-color:$blue;}
.blackbg {background-color:$black;}
.whitebg {background-color:$white;}

.blue {color:$blue;}
.black {color:$black;}
.white {color:$white;}
.lightGrey {color:$lightGrey;}


.section-header {
    text-transform:uppercase;
    font-weight:600;
    margin-bottom:20px;
    &.smallest{font-size:1rem;line-height:1.2rem;}
    &.smaller{font-size:1.2rem;line-height:1.4rem;}
    &.small{font-size:1.4rem;line-height:1.8rem;}
    &.normal{font-size:1.8rem;line-height:2.4rem;}
    &.large {font-size:2.4rem;line-height:3rem;}
    &.larger {font-size:3.6rem;line-height:4.2rem;}
    &.largest {font-size:4.2rem;line-height:4.8rem;}
    &.giant {font-size:6rem;line-height:7.2rem;}
}

.leftAlign {text-align:left;}
.rightAlign {text-align:right;}
.centerAlign {text-align:center;}




.split-section{
        margin-top:60px; margin-bottom:60px;
        img{margin:40px auto;display:block;}
        .smallest{font-size:1rem;line-height:1.2rem;}
        .smaller{font-size:1.2rem;line-height:1.4rem;}
        .small{font-size:1.4rem;line-height:1.6rem;}
        .normal{font-size:1.6rem;line-height:1.8rem;}
        .large {font-size:1.8rem;line-height:2.4rem;}
        .larger {font-size:2.4rem;line-height:3rem;}
        .largest {font-size:3rem;line-height:3.6rem;}
        @media (min-width:768px){
        .smallest{font-size:1rem;line-height:1.2rem;}
        .smaller{font-size:1.2rem;line-height:1.4rem;}
        .small{font-size:1.4rem;line-height:1.8rem;}
        .normal{font-size:1.8rem;line-height:2.4rem;}
        .large {font-size:2.4rem;line-height:3.6rem;}
        .larger {font-size:3.6rem;line-height:4.2rem;}
        .largest {font-size:4.2rem;line-height:4.8rem;}
        }
        @media (min-width:900px){
        .centerAlign.col-xs-12{ width:900px;margin:0 auto;float:none; }
        .centerAlign.col-xs-12.col-md-5, .centerAlign.col-xs-12.col-md-7{width:inherit;}
        }
}

.contact-section {
    .contact-header { font-size:3.6rem; text-transform:uppercase;max-width:100%;line-height:4.2rem;}
    .lead{font-size:2rem;width:600px;margin-top:30px;max-width:100%;}
    .short-line{height:2px;background-color:black;width:400px;display:block;margin-top:20px;margin-bottom:10px;margin-left:-40px;max-width:100%;}
    input, textarea {background-color:#efefef;border:none;padding:20px 30px; line-height:2rem !important;font-size:2rem !important;@include border-radius(10px);
          placeholder{font-size:2rem;}
          
    }
    .btn {&.btn-md{padding:20px 30px;width:auto;float:right;@include border-radius(10px);font-size:2rem;font-weight:400;}}
}


.header, .header.sticky {
    .logo{
        a{width:202px;margin-top:28px;}
    }
}
.navigation, .header.sticky .navigation {
    ul {
        li {box-sizing:border-box;max-width:100%;
            a { padding:41px 16px;
                font-size: 2rem;
                &.btn {
                    padding: 10px 20px;
                    font-weight:400;
                    margin-top: 29px;
                    margin-left: 20px;
                    @include border-radius(5px); 
                    &.btn-blue {background-color: $blue; &:hover {background-color:$darkblue;} }
                    &.btn-lightgrey {background-color:$lightGrey;}
                    &.btn-black {background-color:$black;}
                    &.btn-white {background-color:$lightGrey;}
                    &.txt-white {color:$white !important;}
                    &.txt-blue {color:$blue;}
                }
            }
        }
        @media (max-width: 991px){
            top:100px;
            li{&.nav-button{padding:16px;}
            a{&.btn{max-width:100%;margin-left:0;padding:20px;}}}
        }
    }
}

.navigation ul li:hover a, .header.sticky .navigation ul ul > li:hover > a,
.header.sticky .navigation ul > li:hover > a, .navigation > ul > li.active > a,
.navigation > ul > li > ul > li.active > a,
.navigation > ul > li > ul > li > ul > li.active > a, .header.sticky .navigation ul ul > li.active > a, .header.sticky .navigation ul > li.active > a,
.header.sticky .navigation > ul > li > ul > li.active > a, .header.sticky .navigation > ul > li > ul > li > ul > li.active > a, .accordion-title.active, a:hover {
    color: $blue;
}

.btn {
    padding: 10px 40px;
    margin-top: 30px;
    @include border-radius(10px);
    font-weight: 400;
    white-space: normal;

    &.btn-blue {
        background-color: $blue;

        &:hover {
            background-color: $darkblue;
        }

        &.btn-outline {
            background-color: inherit;
            border-color: $blue;
            border: 3px solid $blue;
        }
    }

    &.btn-white {
        background-color: $white;

        &.btn-outline {
            background-color: inherit;
            border-color: $white;
            border: 3px solid $white;
        }
    }
}
.txt-white {color:$white !important;}
.txt-blue {color:$blue !important;}

ul.link {display:table;margin:0 auto; -webkit-padding-start:0; width:auto;
       li{margin:0;padding:0;display:inline-block;margin-left:10px;border-left:1px solid black;padding-left:10px;
          &:first-child {border-left:none;margin-left:0;padding-left:0;}
       }
}

.accordion-section {border:none !important;font-size:4.2rem;line-height:4.8rem;
                    p{font-size:2.4rem;line-height:3.6rem;}
}
.faq-section { h3{font-size:4.2rem;}
               a{color:$blue;text-decoration:underline;}
}

.mobile-menu-icon, .header.sticky .mobile-menu-icon{font-size:3rem;}

.successContent {font-size:2rem;}

.footer .copyright {font-size:1rem;}

.botMessage{ display:none;font-size:2rem;}


.accordion-content {max-width:1000px;}

sup {font-size:50%; top:-.75rem;}

.field-validation-error {color:red;}

.modal {z-index:10000;
        .modal-dialog{width:1000px;max-width:100%;}
        .modal-content{width:1000px;min-height:500px;max-width:100%;
                       img{max-width:100%;width:250px;display:block;margin:40px auto;
                           &.small-logo{width:300px;margin:0;margin-top:20px;}
                       }
        }
    .large p{font-size:2.4rem;line-height:3rem;}
    .giant {font-size:4rem;line-height:4.8rem;font-weight:600;margin-top:60px;margin-bottom:20px;
            @media (min-width:768px){
                font-size:6rem;line-height:7.2rem;
            }
    }
    .btn-close {display:block;background:$blue;margin:20px auto; color:#fff;}

}